<app-menu-overlay></app-menu-overlay>
<div class="wrapper">
  <app-nav-bar></app-nav-bar>
  <main>
    <div class="safe-area-top-spacing pt-5"></div>
    <router-outlet></router-outlet>
    <div class="safe-area-bottom-spacing"></div>
  </main>
  <app-footer class="footer"></app-footer>
</div>
<app-async-state-overlay [processKeys]="asyncProcessKeys"></app-async-state-overlay>
