import { AppEnvironment, Environment } from 'ngx-esprio-shared';

export const environment: AppEnvironment = {
  production: false,
  environment: Environment.nutrition,
  apiBaseUrl: 'https://dev.esprio.de/api/v1',
  // apiBaseUrl: 'http://localhost:3000/api/v1',
  monitoringApp: 'https://monitoring.dev.esprio.de',
  userApp: 'https://dev.esprio.de',
  useMockServices: false,
  defaultLanguage: 'de',
  disabledAppFeatures: [],
};
